

import firebase from "firebase";
import { encodeString, memorySizeOf_inBytes } from "../util/misc";
import { message } from "antd";
import Mixpanel from "./Mixpanel";

const database                      = firebase.database();

type NoUndefined<T> = {
    [K in keyof T]: Exclude<T[K], undefined>;
};

interface BatchedUpdatesObject {
    [address: string] : NoUndefined<Record<string, any>>;//make sure Object has no undefined values as firebase will not allowed undefined values
}



class CloudDatabase {

    isReadAllowed: boolean;             //this will control whether user is allowed to read from the database or not
    isChangeAllowed: boolean;           //this will control whether user is allowed to make changes to the database or not

    constructor(){
        this.isReadAllowed          = true;
        this.isChangeAllowed        = true;
    }

    update(updates: BatchedUpdatesObject){
        if (!this.isChangeAllowed){
            message.error('Updates have been blocked for this account, due to suspicious traffic');
            return false;
        }

        //do not allow undefined in the address
        for (let oneAddress in updates){
            if (oneAddress === undefined){
                message.error('One of the addresses in the update object is undefined');
                return false;
            }
            if (String(oneAddress).includes('/undefined')){
                message.error('One of the addresses in the update object is undefined');
                return false;
            }
        }

        return database.ref().update(updates)
    }

    async read(address: string){
        if (!this.isReadAllowed){
            message.error('Reads have been blocked for this account, due to suspicious traffic');
            return false;
        }

        if (address === undefined){
            message.error('The address for read object is undefined');
            return false;
        }
        if (String(address).includes('/undefined')){
            message.error('The address for read object is undefined');
            return false;
        }

        if (address === 'USERS' || address === '/USERS' || address === 'USERS/' || address === '/USERS/'){
            message.error('Cannot read from root USERS address');
            return false;
        }

        let snapshot = await database.ref(address).once('value');
        return snapshot.val();
    }

    listen({address, callback}: {address: string, callback: (snapshot: firebase.database.DataSnapshot)=>void}){
        if (!this.isReadAllowed){
            message.error('Listens have been blocked for this account, due to suspicious traffic');
            return false;
        }

        if (address === undefined){
            message.error('The address for read object is undefined');
            return false;
        }
        if (String(address).includes('/undefined')){
            message.error('The address for read object is undefined');
            return false;
        }

        if (address === 'USERS' || address === '/USERS' || address === 'USERS/' || address === '/USERS/'){
            message.error('Cannot read from root USERS address');
            return false;
        }

        return database.ref(address).on('value', callback);
    }

    delete(deleteAddrArray: string[]){
        if (!this.isChangeAllowed){
            message.error('Updates have been blocked for this account, due to suspicious traffic');
            return false;
        }

        //do not allow delete from any addresses which are of single length example USERS/ or if it contains undefined in the address string
        for (let oneAddress of deleteAddrArray){
            if (oneAddress === undefined){
                message.error('One of the addresses in the delete object is undefined');
                return false;
            }
            if (String(oneAddress).includes('/undefined')){
                message.error('One of the addresses in the delete object is undefined');
                return false;
            }

            if (oneAddress.split('/').length === 1){
                message.error('Cannot delete from root addresses');
                return false;
            }
        }

        return database.ref().update(deleteAddrArray.map(oneAddr=>({[oneAddr]: null})))
    }


    getPushKey({address}: {address: string}){
        return (database.ref(address).push().key)
    }

    async createNewTeacherDatabase({uid, email, phone, name, type, referralID, isSignUpZoom, isBusinessAccount}){

        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';
        
        console.log('Creating New Teacher Database with VAL: ', {uid, email, name, phone, isBusinessAccount});

        let ref = database.ref('USERS/' + uid);

        let timeStamp = new Date().getTime();

        let data = {
            UID: uid,
            PublicInfo: {
                UID             : uid,
                UserName        : encodeString(name),
                UserEmail       : encodeString(email),
                UserProffession : "Teacher",
                TeacherType     : "Institution",
                OnlinePayment   : "Off",
                UserInstitution : 'unset',
                UserPhone       : encodeString(phone),
                UserLocation    : 'unset',
                avatarURL       : "https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png",
                Date            : timeStamp,
            }
        };

        if (isBusinessAccount){
            data['ReactAppMode'] = 'SMS_GATEWAY';
        }

        if (referralID){
            data['ReferredBy'] = {'UID':referralID};
            //need to update the referred database that he has successfully refrred an account
            database.ref(`USERS/${referralID}/PublicInfo/Referrals/${uid}`).update({
                referralID  : uid,
                timeStamp   : (new Date()).getTime()
            })
        }


        let p1 = ref.update(data);
        let p2 = database.ref(`email-uid/`).update({
            [encodeString(email)]:  uid
        });

        let p3
        let p4
        let p5
        let p6

        if(isSignUpZoom){
            p3 = database.ref(`USERS/${uid}`).update({ReactAppMode : 'SMS_LMS_GATEWAY'})
            p4 = database.ref(`USERS/${uid}/PublicInfo/DO-NOT-SHOW-NEWS-KEYS`).update({'1662748674': '1662748674'})

            let data = {
                activated       :   (new Date()).getTime(),
                price           :   '0',
                by              :   uid,
                route           :   'zoom-meeting',
                key             :   'ZoomAppIntegration'
            }

           p5 = database.ref(`USERS/${uid}/InstalledTools`).update({
                'ZoomAppIntegration' :data
            })

            p6 = database.ref(`USERS/${uid}/pinnedTools/ZoomAppIntegration`).update({
                'enabled' : true,
                'route'   :'zoom-meeting',
                'title'   : 'Zoom Integration',
                'sideBarIcon' : 'https://f002.backblazeb2.com/file/Edutech-Assets/React-App-AddOns-Icons/icons8-zoom-400.png'
            })
           
        }



        return Promise.all([p1, p2, p3, p4, p5, p6]);
    }

    async createNewAdminDatabase({uid, email, name, phone, type}){

        console.log('Creating New ADMIN Database with VAL: ', {uid, email, name, phone});


        if (!email) email = 'unset@gmail.com';
        if (!phone) phone = 'Unset';

        let ref = database.ref('USERS/' + uid);
        
        let data = {
            UID: uid,
            PublicInfo: {
                UID: uid,
                UserName: encodeString(name),
                UserEmail: encodeString(email),
                UserProffession: 'Admin',
                UserInstitution: 'Unset',
                UserPhone: encodeString(phone),
                avatarURL: 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png',
            }
        }

        let p1 = ref.update(data);
        let p2 = database.ref(`email-uid/`).update({
            [encodeString(email)]:  uid
        });

        return Promise.all([p1, p2]);
    }

    logRealTimeDatabase_DownloadUsage({userID, downloadAddressLocation, downloadFeatureType, downloadedJSON}: {userID: string, downloadAddressLocation: string, downloadFeatureType: string, downloadedJSON: Object}){
        Mixpanel.recordProxy({
            eventName               : 'FIREBASE-REALTIME-DATABASE-DOWNLOAD-USAGE',
            eventProperties         : { 
                downloadAddress         : downloadAddressLocation, 
                featureType             : downloadFeatureType, 
                downloadSizeBytes       : memorySizeOf_inBytes(downloadedJSON),
                distinct_id             : userID 
            },
            distinct_id             : userID,
            recordEvenInLocalhost   : true
        });
    }
}

export default new CloudDatabase()