import { memo, useCallback, useEffect, lazy, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from "react-intl";

import AppLocale from "lngProvider";
import { setInitUrl } from "appRedux/actions/Auth";

import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL, THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

import "../../i18n"
import {get, set, setWith} from "lodash-es";

import { updateInLocalDatabase, updateModeratingTeacherInLocalDatabase, updateAdminedTeacherInLocalDatabase, updateStudentTeacherInLocalDatabase, setActiveTeacherUID, setAllTeachersUID, updateSMSShared, updateFirstLoadProgressBar, updateModeratingTeacherInRestrictedDatabase, updateNestedLocalDatabase, updateSharedPreferences, updateOwnDataForModerator, updateOwnDataForAdmin } from "../../appRedux/actions";
import { auth } from "../../firebase/firebase";

import firebase from 'firebase';
import Mixpanel from "../../api/Mixpanel";
import { decodeString, formatDateAMPM, returnMappedProfessionRoute_Route } from "../../util/misc";
import OnlineAdmissionStudent from "../SinglePublicPages/OnlineAdmissionStudent";
import { updateSidebar } from "../../appRedux/actions/SidebarActions";
import LegacyOneTimeScripts from "../../api/LegacyOneTimeScripts";
import CloudDatabase from "../../api/CloudDatabase";

const MainApp = lazy(() => import("./MainApp"));
const Welcome = lazy(() => import("../Welcome"));
const Jaago = lazy(() => import("../SinglePublicPages/Jaago"));
const SignIn = lazy(() => import("../SignIn")); 
const SignUp = lazy(() => import("../SignUp"));
const DevLogin = lazy(() => import("../SinglePublicPages/DevLogin"));
const PhoneLogin = lazy(() => import("../PhoneLogin"));
const OpenDemoTeacher = lazy(() => import("../OpenDemoTeacher"));
const ForgotPassword = lazy(() => import("../ForgotPassword"));
const ForceLogout = lazy(() => import("../ForceLogout"));
const PaymentFail = lazy(() => import("../SinglePublicPages/PaymentFail"));
const PaymentSuccess = lazy(() => import("../SinglePublicPages/PaymentSuccess"));
const StudentAcceptPayInvoiceV2 = lazy(() => import("../SinglePublicPages/StudentAcceptPayInvoiceDRAFTV2"));
const TakePicture = lazy(() => import("../SinglePublicPages/TakePicture"));
const AccessControlQRScanner = lazy(() => import("../SinglePublicPages/AccessControlQRScanner"));
const AccessControlQRScannerNimic = lazy(() => import("../SinglePublicPages/AccessControlQRScanner-Nimic"));
const StudentQRCodeShow = lazy(() => import("../SinglePublicPages/StudentQRCodeShow"));
const ExternalUserQRCodeShow = lazy(() => import("../SinglePublicPages/ExternalUserQRCodeShow"));
const AccessCodesForAllStudents = lazy(() => import("../SinglePublicPages/AccessCodesForAllStudents"));
const AccessCodesForAllExternalUsers = lazy(() => import("../SinglePublicPages/AccessCodesForAllExternalUsers"));
const StudentAutoLogin = lazy(() => import("../SinglePublicPages/StudentAutoLogin"));
const OnlinePayForStudent = lazy(() => import("../SinglePublicPages/OnlinePayForStudent"));
const ServerStatus = lazy(() => import("../SinglePublicPages/ServerStatus"));
const Support = lazy(() => import("../SinglePublicPages/Support"));
const AccountLabeler = lazy(() => import("../SinglePublicPages/AccountLabeler"));

const ZoomDocumentation = lazy(() => import("../SinglePublicPages/ZoomDocumentation"));
const EduAdmin = lazy(() => import("../SinglePublicPages/EdutechsAdminPages/EduAdmin"));
const URLShortenerRedirect = lazy(() => import("../SinglePublicPages/URLShortenerRedirect"));
const EduCastRecordVideo = lazy(() => import("../SinglePublicPages/EduCastRecordVideo"));
const OnlinePayBalanceOfAccounts = lazy(() => import("../SinglePublicPages/EdutechsAdminPages/OnlinePayBalancesOfAccounts"));

const AboutUs = lazy(() => import("../SinglePublicPages/AboutUs"));
const EduchatConnect = lazy(() => import("../SinglePublicPages/EduConnect/EduchatAppDownloadAndJoin"));
const Careers = lazy(() => import("../SinglePublicPages/Careers"));
const ViewReport = lazy(() => import("../ViewReport"));
const AViewReport = lazy(() => import("../AViewReport"));
const AllNews = lazy(() => import("../AllNews"));
const OpenNews = lazy(() => import("../OpenNews"));
const Privacy = lazy(() => import("../SinglePublicPages/Privacy"));
const Terms = lazy(() => import("../SinglePublicPages/Terms"));
const PrivateVideoCourse = lazy(() => import("../PrivateVideoCourse"));
const PublicVideoCourse = lazy(() => import("../PublicVideoCourse"));
const AdminInvoice = lazy(() => import("../SinglePublicPages/AdminInvoice"));
const StudentPaidReceipt = lazy(() => import("../SinglePublicPages/StudentPaidReceipt"));
const LMSPaidReceipt    =   lazy(() => import("../SinglePublicPages/LMSPaidReceipt"));
const MobileLMSSubscriptionPage    =   lazy(() => import("../SinglePublicPages/MobileLMSSubscriptionPage"));
const StudentScanScript = lazy(() => import("../SinglePublicPages/StudentScanScript"));
const FbLoginComplete = lazy(() => import("../SinglePublicPages/FbLoginComplete"));
const ZoomAuth = lazy(() => import("../ZoomAuth"));
const BusinessInsights = lazy(() => import("../SinglePublicPages/BusinessInsights"));
const ActivityLogSummary = lazy(() => import("../SinglePublicPages/BusinessInsights/ActivityLogSummary"));
const PaymentFailForStudent = lazy(() => import("../SinglePublicPages/PaymentFailForStudent"));
const CreateFaceRecogPrint = lazy(() => import("../SinglePublicPages/CreateFaceRecogPrint/"));
const DigitalPaymentQRDownload = lazy(() => import("../DigitalPaymentQRDownload"));
const PalestineCampaign = lazy(() => import("../SinglePublicPages/PalestineCampaign"));
const SentinelConnectedDevices = lazy(() => import("../SinglePublicPages/EdutechsAdminPages/SentinelConnectedDevices"));
const ATableViewReport = lazy(() => import("../ATableViewReport"));
const TableViewReport = lazy(() => import("../TableViewReport"));
const EduCastController = lazy(() => import("../SinglePublicPages/EduCastController"));
const TableViewReportAssessmentView = lazy(() => import("../TableViewReportAssessmentView"));
const ATableViewReportAssessmentView = lazy(() => import("../ATableViewReportAssessmentView"));
const NFCControllerHub = lazy(() => import("../SinglePublicPages/NFCControllerHub"));
const SentinelLandingPage = lazy(() => import("../SinglePublicPages/SentinelLandingPage"));
const UnderMaintenance = lazy(() => import("../UnderMaintenance"));
const ConfigLandingPage = lazy(() => import("../SinglePublicPages/ConfigLandingPage"));
const PublicStudentParentChat = lazy(() => import("../SinglePublicPages/EduConnect/StudentParentChat"));
const BkashPaymentError = lazy(() => import("../BkashPaymentError"));
const BkashTest = lazy(() => import("../SinglePublicPages/BkashTest"));
const GeneralParticipantChat = lazy(() => import("../SinglePublicPages/EduConnect/GeneralParticipantChat"));

const database          = firebase.database();
const performance       = firebase.performance();


const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: location }
                    }}
                />}
    />;

const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
        document.body.classList.remove('full-layout');
        document.body.classList.remove('framed-layout');
        document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
        document.body.classList.remove('boxed-layout');
        document.body.classList.remove('full-layout');
        document.body.classList.add('framed-layout');
    }
};

const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER) {
        document.body.classList.add('full-scroll');
        document.body.classList.add('horizontal-layout');
    } else {
        document.body.classList.remove('full-scroll');
        document.body.classList.remove('horizontal-layout');
    }
};

let styleSheetLink = document.createElement('link');
styleSheetLink.type = 'text/css';
styleSheetLink.rel = 'stylesheet';
document.body.appendChild(styleSheetLink);



const teacherAddressGlobal_Locations_To_Map_For_Student         = [['UID'], ['PublicInfo'], ['VideoCourse'], ['CloudStorage'], ['Preferences'], ['ReportCards']];
const studentInnerBatchKeysToDownload                           = [['BatchAvatar'], ['BatchName'], ['Folders-SpecialShared'], ['InstituteType'], ['PossibleDates'], ['StartDate'], ['Status'], ['StreamColor'], ['Timings'], ['Tution'], ['VideoGroups-SpecialShared'], ['createdAt'], ['tCollectionMonth'], ['Assessment'], ['Events'], ['specialPayments']];
const ignoreGradeKeysToLoadForStudents                          = ['Others', 'Management Internal', 'Hidden'];

const nonAuthForceRedirectRoutes = [
    { path: '/welcome', component: Welcome },
    { path: '/donate', component: PalestineCampaign },
    { path: '/aboutus', component: AboutUs },
    { path: '/news', component: AllNews },
    { path: '/opennews', component: OpenNews },
    { path: '/Privacy', component: Privacy },
    { path: '/Terms', component: Terms },
    { path: '/Support', component: Support },
    { path: '/devlogin', component: DevLogin },
    { path: '/paymentfail', component: PaymentFail },
    { path: '/student-payment-fail', component: PaymentFailForStudent },
    { path: '/paymentsuccess', component: PaymentSuccess },
    { path: '/careers', component: Careers },
    { path: '/jaago', component: Jaago },
    { path: '/viewreport', component: ViewReport },
    { path: '/aviewreport', component: AViewReport },
    { path: '/atableviewreport', component: ATableViewReport },
    { path: '/atableviewreportassessmentview', component: ATableViewReportAssessmentView },
    { path: '/digital-pay-qr-download', component: DigitalPaymentQRDownload },
    { path: '/open-demo-teacher', component: OpenDemoTeacher },
    { path: '/private-video-course', component: PrivateVideoCourse },
    { path: '/public-video-course', component: PublicVideoCourse },
    { path: '/take-picture', component: TakePicture },
    { path: '/admin-invoice', component: AdminInvoice },
    { path: '/student-payment-receipt', component: StudentPaidReceipt },
    { path: '/lms-sub-paid-receipt', component: LMSPaidReceipt },
    { path: '/lms-resub', component: MobileLMSSubscriptionPage },
    { path: '/spr', component: StudentAcceptPayInvoiceV2 },
    { path: '/scan-script', component: StudentScanScript },
    { path: '/complete-fb-login', component: FbLoginComplete },
    { path: '/account-labeler', component: AccountLabeler },
    { path: '/access-control-qr-scanner', component: AccessControlQRScanner },
    { path: '/access-control-qr-scanner-nimic', component: AccessControlQRScannerNimic },
    { path: '/qr-code-student', component: StudentQRCodeShow },
    { path: '/qr-code-other-user', component: ExternalUserQRCodeShow },
    { path: '/all-student-access-codes', component: AccessCodesForAllStudents },
    { path: '/all-external-users-access-codes', component: AccessCodesForAllExternalUsers },
    { path: '/s-auto-login', component: StudentAutoLogin },
    { path: '/s-pay', component: OnlinePayForStudent },
    { path: '/s-online-admission', component: OnlineAdmissionStudent },
    { path: '/business-insight', component: BusinessInsights },
    { path: '/zoom-auth', component: ZoomAuth },
    { path: '/zoom/documentation', component: ZoomDocumentation },
    { path: '/server-status/', component: ServerStatus },
    { path: '/activity-log-summary', component: ActivityLogSummary },
    { path: '/create-face-recog-print', component: CreateFaceRecogPrint },
    { path: '/Edutechs-Staff-Admin', component: EduAdmin },
    { path: '/Sentinel-Connected-Devices', component: SentinelConnectedDevices },
    { path: '/Online-Pay-Balances-Of-Account', component: OnlinePayBalanceOfAccounts },
    { path: '/r', component: URLShortenerRedirect, pathMatcherOverrideForNonDBLoad: '/r?' },
    { path: '/tableviewreport', component: TableViewReport },
    { path: '/tableviewreportassessmentview', component: TableViewReportAssessmentView },
    { path: '/record-educast', component: EduCastRecordVideo },
    { path: '/control-educast', component: EduCastController },
    { path: '/nfc-control-hub', component: NFCControllerHub },
    { path: '/Sentinel', component: SentinelLandingPage },
    { path: '/under-maintenance', component: UnderMaintenance },
    { path: '/config-landing-page', component: ConfigLandingPage },
    { path: '/edc', component: PublicStudentParentChat },
    { path: '/pay-with-bkash', component: BkashTest },
    { path: '/bkash-payment-error', component: BkashPaymentError },
    { path: '/edc-acc', component: GeneralParticipantChat },
    { path: '/edc-join', component: EduchatConnect },
];

const withAuthForceRedirectRoutes = [
    { path: '/signin', component: SignIn },
    { path: '/login', component: SignIn },
    { path: '/signup', component: SignUp },
    { path: '/phone-login', component: PhoneLogin },
    { path: '/forgot-password', component: ForgotPassword },
    { path: '/force-logout', component: ForceLogout },
]



const App = () => {

    const locale                        = useSelector(({ settings }) => settings.locale);
    const navStyle                      = useSelector(({ settings }) => settings.navStyle);
    const layoutType                    = useSelector(({ settings }) => settings.layoutType);
    const themeColor                    = useSelector(({ settings }) => settings.themeColor);
    const themeType                     = useSelector(({ settings }) => settings.themeType);
    const isDirectionRTL                = useSelector(({ settings }) => settings.isDirectionRTL);

    const dispatch                      = useDispatch();

    const { authUser, 
        initURL, 
        db 
    }                                   = useSelector(({ auth }) => auth);

    const location                      = useLocation();
    const history                       = useHistory();
    const match                         = useRouteMatch();

    const loadOnceComplete                      = useRef(false);
    const setupSMSSharingOnceComplete           = useRef(false);
    const fetchSharedPreferencesOnceComplete    = useRef(false);

    const fetchSharedPreferencesFromParentAccounts = useCallback((nonFullLoadedDB)=>{
        if (fetchSharedPreferencesOnceComplete.current === true) return false;

        //if this is an admined teacher account then fetch shared preferences from admin account
        if (get(nonFullLoadedDB, ['PublicInfo', 'UserProffession'], 'UNKNOWN') === 'Teacher'){
            let adminUID = get(nonFullLoadedDB, ['Admined'], undefined);
            if (adminUID){
                database.ref(`USERS/${adminUID}/SharedToChildPreferences`).once('value').then(snapshot=>{
                    if (snapshot.val()){
                        dispatch(updateSharedPreferences({updatedData: snapshot.val(), nestedAddressArr: ['VolatileSharedToChildPreferences']}));
                    }
                })
            }
        }

        //if this is an moderator account then fetch shared preferences from teacher account but if teacher account is admined then fetch from the admin account instead
        if (get(nonFullLoadedDB, ['PublicInfo', 'UserProffession'], 'UNKNOWN') === 'TeacherAssistant'){

            let acceptedBatches   = get(nonFullLoadedDB, ['AcceptedBatches'], {});

            for (let bKey in acceptedBatches){
                let TeacherUID = get(acceptedBatches, [bKey, 'TeacherUID'], undefined);
                if (TeacherUID){
                    
                    database.ref(`USERS/${TeacherUID}/Admined`).once('value').then(snapshot=>{

                        if (snapshot.val()){
                            //yes this is an admined account so fetch the shared preferences from master admin account
                            database.ref(`USERS/${snapshot.val()}/SharedToChildPreferences`).once('value').then(snapshot=>{
                                if (snapshot.val()){
                                    dispatch(updateSharedPreferences({updatedData: snapshot.val(), nestedAddressArr: ['VolatileSharedToChildPreferences']}));
                                }
                            })
                        }
                        else{
                            //no this is un-admined teacher account so just use the teacher's shared preferences
                            database.ref(`USERS/${TeacherUID}/SharedToChildPreferences`).once('value').then(snapshot=>{
                                if (snapshot.val()){
                                    dispatch(updateSharedPreferences({updatedData: snapshot.val(), nestedAddressArr: ['VolatileSharedToChildPreferences']}));
                                }
                            })
                        }

                    });

    
                    //just work with the very first teacher account found under this moderator account
                    break
                }
            }
        }

        fetchSharedPreferencesOnceComplete.current = true;

    }, [dispatch]);

    //this will trigger across all accounts and update the local redux db with shared SMS-Quota from accounts
    const setupSMSSharing = useCallback((db)=>{

        if (setupSMSSharingOnceComplete.current === true) return false;

        // console.log('Setting up SMS Sharing..', db);
        let smsSharedFrom = get(db, ['SMS-Shared-From'], {});

        for (let oneSharedFromUID in smsSharedFrom){
            database.ref(`USERS/${oneSharedFromUID}/SMS-Quota`).off();
            database.ref(`USERS/${oneSharedFromUID}/SMS-Quota`).on('value', snapshot=>{
                if (snapshot.val()){
                    dispatch(updateSMSShared({updatedData: snapshot.val(), nestedAddressArr: [oneSharedFromUID]}));
                }
            })
        }

        setupSMSSharingOnceComplete.current = true;

    }, [dispatch]);

    const updateUISubjectsForStudentsInOwnDatabase = useCallback(async (allModBatchKeysUNIQUE)=>{
        for (let oneClassroom of allModBatchKeysUNIQUE){

            let {Grade, Subject, TeacherUID} = oneClassroom;

            let UiSubject           = Subject;
            database.ref(`USERS/${TeacherUID}/UserClass/${Grade}/${Subject}/UiSubject`).once('value').then(thisUISubject=>{
                if (thisUISubject.val()) UiSubject = thisUISubject.val();
                dispatch(updateStudentTeacherInLocalDatabase({objToAdd: {
                    UiSubject: UiSubject
                }, locationArr: [TeacherUID, 'UserClass', Grade, Subject]}));
            })
        }

    }, [dispatch]);

    //this will update separate node keys under batch json for students
    const updateBatchInnerNodeForStudentsInOwnDatabase = useCallback(async (allModBatchKeysUNIQUE)=>{
        for (let oneClassroom of allModBatchKeysUNIQUE){
            let {Grade, Subject, BatchKey, TeacherUID} = oneClassroom;

            let newToAddJSON    =   {};
            let promiseArr      =   [];

            for (let oneNodeToDownload of studentInnerBatchKeysToDownload){
                promiseArr.push(database.ref(`USERS/${TeacherUID}/UserClass/${Grade}/${Subject}/Streams/${BatchKey}/${oneNodeToDownload}`).once('value'));
            }

            Promise.all(promiseArr)
                .then(snapshotArr=>{
                    snapshotArr.forEach((snapVal, index)=>{
                        set(newToAddJSON, [studentInnerBatchKeysToDownload[index]], snapVal.val() ? snapVal.val() : undefined);
                    })
                    dispatch(updateStudentTeacherInLocalDatabase({objToAdd: newToAddJSON, locationArr: [TeacherUID, 'UserClass', Grade, Subject, 'Streams', BatchKey]}));
                })
        }

    }, [dispatch]);

    const updateDBLoadingMessage = useCallback(({type, text}) => {
        const loadingKey                        = 'first-load-loading-key';
        if (!nonAuthForceRedirectRoutes.some(locationObj => (window.location.href.includes(locationObj.pathMatcherOverrideForNonDBLoad ? locationObj.pathMatcherOverrideForNonDBLoad : locationObj.path)))) {
            if (loadOnceComplete.current === false){
                if (type === 'loading'){
                    message.loading({
                        content: text,
                        key:loadingKey,
                    });
                }
                else if (type === 'success'){
                    message.success({
                        content: text,
                        key:loadingKey,
                    });
                }
            }
        }
    
    }, []);

    //for teacher assistant will read moderating teacher databases and sync local redux with it
    const setupOneTeacherForModerator = useCallback(async ({tuid, allModBatchKeysUNIQUE, accountOwnerUID})=>{

        let uidUSED             =   tuid; 

        database.ref(`USERS/${uidUSED}/`).off();
        database.ref(`USERS/${uidUSED}/`).on("value", snapshot=>{

            let userData = snapshot.val();

            CloudDatabase.logRealTimeDatabase_DownloadUsage({userID:accountOwnerUID, downloadAddressLocation: `USERS/${uidUSED}`, downloadFeatureType: `ONE-TEACHER-DB-LOAD-FOR-MODERATOR`, downloadedJSON: snapshot.val() ? snapshot.val() : {}});

            if (!snapshot.exists()){
                console.log('A Teachers Database returned as undefined!');
                return false;
            } 

            //need to manipulate this JSON to have a record of all students in different batches in one unique list
            let {outputAsArr, outputAsJSON} = returnAllStudentsAsOneUniqueList(userData, allModBatchKeysUNIQUE);

            set(userData, ['VolatileLocalData', 'UniqueStudentAlphabeticalList'], outputAsArr);
            set(userData, ['VolatileLocalData', 'UniqueStudentJSONList'], outputAsJSON);
            
            let duplicateJSON_WithRestrictedData = {};

            //need to go through all batch-keys and remove any that are not added for this moderator
            for (let grade in get(userData, ['UserClass'], {})){
                for (let subject in get(userData, ['UserClass', grade], {})){
                    for (let oneBatch in get(userData, ['UserClass', grade, subject, 'Streams'], {})){
                        const batchData = get(userData, ['UserClass', grade, subject, 'Streams', oneBatch], {})
                        if (!allModBatchKeysUNIQUE?.includes(`${uidUSED}-${oneBatch}`)){
                            set(userData, ['UserClass', grade, subject, 'Streams', oneBatch], undefined);
                            set(duplicateJSON_WithRestrictedData, ['UserClass', grade, subject, 'Streams', oneBatch], batchData);
                        }
                    }
                }
            }

            dispatch(updateModeratingTeacherInLocalDatabase({objToAdd: {[uidUSED]: userData}}));
            dispatch(updateModeratingTeacherInRestrictedDatabase({objToAdd: {[uidUSED]: duplicateJSON_WithRestrictedData}}));

            console.log('Completed loading moderating Teacher.. ', uidUSED, decodeString(get(userData, ['PublicInfo', 'UserName'], 'Unknown-User')), formatDateAMPM(new Date()));
        });

    }, [dispatch]);


    const setupOneTeacherForStudent_V2 = useCallback(async ({Grade, Subject, BatchKey, TeacherUID, suid})=>{


        database.ref(`USERS/${TeacherUID}/UserClass/${Grade}/${Subject}/Streams/${BatchKey}/AcceptedStudents/${suid}`).off();
        database.ref(`USERS/${TeacherUID}/UserClass/${Grade}/${Subject}/Streams/${BatchKey}/AcceptedStudents/${suid}`).on("value", async snapshot=>{

            CloudDatabase.logRealTimeDatabase_DownloadUsage({userID:suid, downloadAddressLocation: `USERS/${TeacherUID}/UserClass/${Grade}/${Subject}/Streams/${BatchKey}/AcceptedStudents/${suid}`, downloadFeatureType: `ONE-CLASSROOM-DB-LOAD-FOR-STUDENT`, downloadedJSON: snapshot.val() ? snapshot.val() : {}});

            if (!snapshot.exists()){
                console.log('A Student Teacher AcceptedStudent Database returned as undefined!');
                return false;
            } 
            
            dispatch(updateStudentTeacherInLocalDatabase({objToAdd: snapshot.val(), locationArr: [TeacherUID, 'UserClass', Grade, Subject, 'Streams', String(BatchKey), 'AcceptedStudents', suid]}));
        });


    }, [dispatch]);

    //this will loop through and fetch non-live public global data from teachers and set them for student local database
    const setupTeacherPublicDataForStudentsNON_LIVE_ASYNC_NONBLOCKING = useCallback(async (uniqueTUID)=>{
        for (let oneTUID of uniqueTUID){
            for (let onePlaceToDownloadFrom of teacherAddressGlobal_Locations_To_Map_For_Student){
                database.ref(`USERS/${oneTUID}/${onePlaceToDownloadFrom.join('/')}`).once('value').then(s=>{
                    dispatch(updateStudentTeacherInLocalDatabase({objToAdd: {
                        [onePlaceToDownloadFrom[0]]: s.val()
                    }, locationArr: [oneTUID]}));
                })
            }
        }
    }, [dispatch]);


    const setupInstalledTools_AddOns_Moderators = useCallback((fullCurrentJSON, callback)=>{
        let db = fullCurrentJSON;
        //fetch all available tools from the cloud database
        database.ref(`StaticData/ReactAppTools/`).once('value').then(snapshot=>{
            if (!snapshot.val()) return false;

            let allAvailableToolsJSON = snapshot.val();

            let allToolsARRAY         = [];

            //need to re-order this based on position of tool
            for (let toolKey in allAvailableToolsJSON){
                let toAdd       = allAvailableToolsJSON[toolKey];
                toAdd['key']    = toolKey;
                allToolsARRAY.push(toAdd);
            }
            

            allToolsARRAY.sort((a,b)=>{
                return (parseInt(a['position']) - parseInt(b['position']))
            })

            //need to check here to see if admin of this account or teacher has access to these tools
            //admined accounts will inherit all tools/add-ons that their admins / teachers buy

            let allInstalledToolsOfSuperiorAccounts     = [];
            let allModeratingTeachers                   = get(db, ['VolatileLocalData', 'Moderating'], {});

            let promiseArr = [];
            for (let oneTeacherUID in allModeratingTeachers){

                let installedToolsForThisTeacher        = get(allModeratingTeachers, [oneTeacherUID, 'InstalledTools'], {});

                for (let oneInstalledToolKey in installedToolsForThisTeacher){
                    let toPushJSON          = installedToolsForThisTeacher[oneInstalledToolKey];
                    toPushJSON['key']       = oneInstalledToolKey;
                    allInstalledToolsOfSuperiorAccounts.push(toPushJSON);
                }

                //if this teacher was admined then need to fetch the admin installed tools as well
                let isAdmined       = get(allModeratingTeachers, [oneTeacherUID, 'Admined'], undefined);

                if (isAdmined !== undefined){
                    promiseArr.push(database.ref(`USERS/${isAdmined}/InstalledTools`).once('value')
                        .then(snapshot=>{

                            if (!snapshot.exists()) return false

                            for (let oneInstalledToolKey in snapshot.val()){
                                let toPushJSON          = snapshot.val()[oneInstalledToolKey];
                                toPushJSON['key']       = oneInstalledToolKey;
                                allInstalledToolsOfSuperiorAccounts.push(toPushJSON);
                            }

                        }))
                }

            }

            Promise.all(promiseArr).then(()=>{

                //need to loop through here and only keep unique key arrays with highest expiry dates
                let finalNewUniqueInstalledToolsArray   =   [];
                let keysAlreadyChecked                  =   [];

                for (let oneTool of allInstalledToolsOfSuperiorAccounts){

                    let keyToCheck = oneTool['key'];

                    if (!keysAlreadyChecked.includes(keyToCheck)){
                        //check to see if this key occurs anywhere else in the superior accounts array
                        let workingFinalToolToAdd = oneTool;

                        for (let oneInsideTool of allInstalledToolsOfSuperiorAccounts){

                            let insideKeyToCheck = oneInsideTool['key'];


                            if (insideKeyToCheck === keyToCheck){
                                //check the expiry of both

                                let expiresOnWorkingTool    = get(workingFinalToolToAdd, ['Expires'], undefined);
                                let expiresOnInsideTool     = get(oneInsideTool, ['Expires'], undefined);

                                if (expiresOnWorkingTool !== undefined && expiresOnInsideTool !== undefined){
                                    if (parseInt(expiresOnWorkingTool) < parseInt(expiresOnInsideTool)){
                                        workingFinalToolToAdd = oneInsideTool;
                                    }
                                }

                            }

                            
                        }


                        finalNewUniqueInstalledToolsArray.push(workingFinalToolToAdd);
                        keysAlreadyChecked.push(keyToCheck);
                    }
                }

                callback({
                    allInstalledTools   : finalNewUniqueInstalledToolsArray,
                    keysArray           : keysAlreadyChecked
                })
            })

        });

    }, []);


    const setupOneTeacherForAdmin = useCallback(async ({tuid, accountOwnerUID})=>{

        //also attaches live listen events which with the global window.firstLoad we can understand and then selectively only update redux db instead of doing whole recursive function
        let uidUSED             =  tuid; 

        database.ref(`USERS/${uidUSED}/`).off();
        database.ref(`USERS/${uidUSED}/`).on("value", snapshot=>{

            let userData = snapshot.val();

            CloudDatabase.logRealTimeDatabase_DownloadUsage({userID:accountOwnerUID, downloadAddressLocation: `USERS/${uidUSED}`, downloadFeatureType: `ONE-TEACHER-DB-LOAD-FOR-ADMIN`, downloadedJSON: snapshot.val() ? snapshot.val() : {}});

            if (!snapshot.exists()){
                console.log('A Teachers Database returned as undefined!');
                return false;
            } 

            //need to manipulate this JSON to have a record of all students in different batches in one unique list
            let {outputAsArr, outputAsJSON} = returnAllStudentsAsOneUniqueList(userData);

            set(userData, ['VolatileLocalData', 'UniqueStudentAlphabeticalList'], outputAsArr);
            set(userData, ['VolatileLocalData', 'UniqueStudentJSONList'], outputAsJSON);

            dispatch(updateAdminedTeacherInLocalDatabase({objToAdd: {[uidUSED]: userData}}));

            console.log('Completed loading admin Teacher.. ', uidUSED, decodeString(get(userData, ['PublicInfo', 'UserName'], 'Unknown-User')), formatDateAMPM(new Date()));
        });


    }, [dispatch]);

    //will only read own Database -- add listeners to it and sync it with local redux
    const initialRead = useCallback(async (uid)=>{

        window.firstLoad            = true;
        window.firstLoadTimestamp   = new Date().getTime();

        let uidUSED                 =   uid; //'CAN ENTER OTHER UID TO USE HERE'
        let isFirst                 =   true;

        // If someone open payment receipt than we skip to load full database
        if(window.location.href.includes('student-payment-receipt?key=')){
           return false;
        }

        // If someone open access control scanner than we skip to load full database
        if(window.location.href.includes('access-control-qr-scanner-nimic?key=')){
            return false;
        }

        database.ref(`USERS/${uidUSED}`).off();

        updateDBLoadingMessage({type: 'loading', text: 'Setting up your account..'});
        updateFirstLoadProgressBar({percent: 20});
        return database.ref(`USERS/${uidUSED}`).on("value", async snapshot=>{

            window.firstLoad        =   true;
            let userData            =   snapshot.val();

            console.log('Completed loading user own db.. ', userData, decodeString(get(userData, ['PublicInfo', 'UserName'], 'Unknown-User')), formatDateAMPM(new Date()));

            if (!snapshot.exists()){
                console.log('User Data for this UID was undefined!');
                message.error('Could not find proper database for you. Please sign-up with different account and login');
                auth.signOut();
                window.location.href = `https://edutechs.app/force-logout`;
                return {};
            } 

            if (isFirst === true){
                Mixpanel.init();
                Mixpanel.setUserData({uid:uidUSED, name: get(userData, ['PublicInfo', 'UserName'], 'Unknown-User') ,  email: get(userData, ['PublicInfo', 'UserEmail'], 'Unknown-Email'), proff: get(userData, ['PublicInfo', 'UserProffession'], 'Unknown-Proff')});
                isFirst = false;
            }

            CloudDatabase.logRealTimeDatabase_DownloadUsage({userID:uidUSED, downloadAddressLocation: `USERS/${uidUSED}`, downloadFeatureType: `INITIAL-OWN-DB-READ-ON-FIRST-LOAD`, downloadedJSON: snapshot.val() ? snapshot.val() : {}});

            if (get(snapshot.val(), ['PublicInfo', 'UserProffession'], undefined) === undefined){
                console.log('User Data for this UID was undefined!');
                message.error('Could not find proper database for you. Please sign-up with different account and login');
                auth.signOut();
                window.location.href = `https://edutechs.app/force-logout`;
                return {};
            }

            //check to see which account proff has logged in
            //set-up as Teacher Account
            if (snapshot.val()['PublicInfo']['UserProffession'] === 'Teacher'){
                updateFirstLoadProgressBar({percent: 70});
                //need to manipulate this JSON to have a record of all students in different batches in one unique list
                let {outputAsArr, outputAsJSON} = returnAllStudentsAsOneUniqueList(userData);

                set(userData, ['VolatileLocalData', 'UniqueStudentAlphabeticalList'], outputAsArr);
                set(userData, ['VolatileLocalData', 'UniqueStudentJSONList'], outputAsJSON);

                dispatch(updateInLocalDatabase({ objToAdd: userData}));
                dispatch(updateSidebar(true));

                let teacherUid = get(userData, ['PublicInfo', 'UID'], '')
                updateFirstLoadProgressBar({percent: 100});
                dispatch(setActiveTeacherUID(teacherUid));
                setupSMSSharing(userData);
                fetchSharedPreferencesFromParentAccounts(userData);
                setTimeout(()=>{
                    LegacyOneTimeScripts.run(userData);
                }, 1000);
            }
            //set-up as moderator / teacher assistant account
            else if (snapshot.val()['PublicInfo']['UserProffession'] === 'TeacherAssistant'){
                let moderatingJSON      = get(snapshot.val(), ['AcceptedBatches'], {});

                let allTUID                     = []; 
                let allModBatchKeysUNIQUE       = [];
                let filterTUID_KEYS             = [];

                for (let oneBatch in moderatingJSON){
                    allTUID.push(moderatingJSON[oneBatch]['TeacherUID']);

                    let {Grade, Subject, TeacherUID} = moderatingJSON[oneBatch];

                    allModBatchKeysUNIQUE.push({
                        Grade, Subject, BatchKey: oneBatch, TeacherUID: TeacherUID
                    });

                    filterTUID_KEYS.push(`${TeacherUID}-${oneBatch}`);
                }

                let uniqueTUID = allTUID.filter((v, i, a) => a.indexOf(v) === i);

                // set default active teacher uid
                dispatch(setActiveTeacherUID(uniqueTUID[0]));
                dispatch(setAllTeachersUID(uniqueTUID));

                updateDBLoadingMessage({type: 'loading', text: 'Fetching your added teachers..'});

                //binding of live listen events for various teachers should only be done once - the first time
                if (loadOnceComplete.current === false){
                    for (let oneTUID of uniqueTUID){
                        setupOneTeacherForModerator({tuid: oneTUID, allModBatchKeysUNIQUE: filterTUID_KEYS, accountOwnerUID: uidUSED});
                    }
                }

                if (loadOnceComplete.current === false){
                    //for first load over-write local db with new fresh one..
                    dispatch(updateInLocalDatabase({ objToAdd: userData}));
                }
                else{
                    console.log('Moderator Account already loaded so making shallow update only to own DB portion in Redux db..', userData);
                    dispatch(updateOwnDataForModerator({objToAdd: userData, nestedAddressArr: []}));
                }
                

                dispatch(updateSidebar(true));
                window.firstLoad = false;
                loadOnceComplete.current = true;

                setupSMSSharing(userData);

                //need to update installed tools / add-ons for this moderator
                //fetch inherited installed add-ons for the moderator
                setupInstalledTools_AddOns_Moderators(userData, ({allInstalledTools, keysArray})=>{
                    dispatch(updateNestedLocalDatabase({objToAdd: allInstalledTools, nestedAddressArr: ['VolatileLocalData', 'InstalledTools']}));
                    dispatch(updateNestedLocalDatabase({objToAdd: keysArray, nestedAddressArr: ['VolatileLocalData', 'InstalledToolsKeys']}))
                });

                fetchSharedPreferencesFromParentAccounts(userData);
            }
            //set-up as admin account
            else if (snapshot.val()['PublicInfo']['UserProffession'] === 'Admin'){
                let teacherJSON     = get(snapshot.val(), ['Teachers'], {});
                let allTUID         = []; 

                for (let tuid in teacherJSON){allTUID.push(tuid);}
                let uniqueTUID = allTUID.filter((v, i, a) => a.indexOf(v) === i);

                // set default active teacher uid
                dispatch(setActiveTeacherUID(uniqueTUID[0]));
                dispatch(setAllTeachersUID(uniqueTUID))

                updateDBLoadingMessage({type: 'loading', text: 'Fetching your added teachers..'});

                //binding of live listen events for various teachers should only be done once - the first time
                if (loadOnceComplete.current === false){
                    for (let oneTUID of uniqueTUID){
                        setupOneTeacherForAdmin({tuid: oneTUID, accountOwnerUID: uidUSED});
                    }
                }

                if (loadOnceComplete.current === false){
                    //for first load over-write local db with new fresh one..
                    dispatch(updateInLocalDatabase({ objToAdd: userData}));
                }
                else{
                    console.log('Admin Account already loaded so making shallow update only to own DB portion in Redux db..');
                    dispatch(updateOwnDataForAdmin({objToAdd: userData, nestedAddressArr: []}));
                }

                dispatch(updateSidebar(true));
                window.firstLoad = false;
                loadOnceComplete.current = true;

                setTimeout(()=>{
                    LegacyOneTimeScripts.run(userData);
                }, 1000);

            }
            //set-up as student account
            else if (snapshot.val()['PublicInfo']['UserProffession'] === 'Student'){
                let acceptedClassJSON      = get(snapshot.val(), ['AcceptedClasses'], {});

                let allTUID                     = []; 
                let allModBatchKeysUNIQUE       = [];

                for (let oneBatch in acceptedClassJSON){
                
                    let teacherUID = acceptedClassJSON[oneBatch]['TeacherUID'] ? acceptedClassJSON[oneBatch]['TeacherUID'] : acceptedClassJSON[oneBatch]['UID']; // UID means teacher uid, this data save before JULY, 2022 as UID

                    if(teacherUID === undefined) continue;

                    let {Grade, Subject} = acceptedClassJSON[oneBatch];

                    allTUID.push(teacherUID);
                    if (!ignoreGradeKeysToLoadForStudents.includes(decodeString(Grade))){
                        allModBatchKeysUNIQUE.push({
                            Grade, Subject, BatchKey: oneBatch, TeacherUID: teacherUID
                        });
                    }
                }

                let uniqueTUID = allTUID.filter((v, i, a) => a.indexOf(v) === i);
                // set default active teacher uid
                dispatch(setActiveTeacherUID(uniqueTUID[0]));
                dispatch(setAllTeachersUID(uniqueTUID));

                updateDBLoadingMessage({type: 'loading', text: 'Fetching your specific classrooms..'});

                for (let oneClassroomToLoad of allModBatchKeysUNIQUE){
                    let {Grade, Subject, BatchKey, TeacherUID} = oneClassroomToLoad;
                    setupOneTeacherForStudent_V2({Grade, Subject, BatchKey, TeacherUID, suid:uidUSED});
                }

                setupTeacherPublicDataForStudentsNON_LIVE_ASYNC_NONBLOCKING(uniqueTUID);

                dispatch(updateInLocalDatabase({ objToAdd: userData}));
                dispatch(updateSidebar(true));

                updateUISubjectsForStudentsInOwnDatabase(allModBatchKeysUNIQUE);
                updateBatchInnerNodeForStudentsInOwnDatabase(allModBatchKeysUNIQUE);

                setTimeout(()=>{
                    window.firstLoad = false;
                    loadOnceComplete.current = true;
                }, 2000);
            }

        });

    }, [dispatch, updateBatchInnerNodeForStudentsInOwnDatabase, setupOneTeacherForStudent_V2,  updateDBLoadingMessage, setupTeacherPublicDataForStudentsNON_LIVE_ASYNC_NONBLOCKING, updateUISubjectsForStudentsInOwnDatabase,  setupOneTeacherForModerator, setupOneTeacherForAdmin, setupInstalledTools_AddOns_Moderators, setupSMSSharing, fetchSharedPreferencesFromParentAccounts]);


    const returnAllStudentsAsOneUniqueList = (fullDB, filterTUID_BatchKeys) => {

        let outputAsArr     = [];
        let outputAsJSON    = {};
        let userClass       = get(fullDB, ['UserClass'], {});

        for (let grade in userClass){
            for (let subject in userClass[grade]){
                for (let batch in userClass[grade][subject]['Streams']){

                    if (filterTUID_BatchKeys){
                        //this means that a set of batch keys were passed in and only these are to be included
                        // if (!filterTUID_BatchKeys.includes(`${thisTUID}-${batch}`)) continue;
                    }

                    let acceptedStudents = get(fullDB, ['UserClass', grade, subject, 'Streams', batch, 'AcceptedStudents'], undefined);

                    if (acceptedStudents){
                        for (let oneSUID in acceptedStudents){

                            let dataToAdd               = get(acceptedStudents, [oneSUID], {});

                            dataToAdd['Name']           = acceptedStudents[oneSUID]['Name'];
                            dataToAdd['Phone']          = acceptedStudents[oneSUID]['Phone'];
                            dataToAdd['UID']            = acceptedStudents[oneSUID]['UID'];
                            dataToAdd['Institution']    = acceptedStudents[oneSUID]['Institution'];
                            dataToAdd['Email']          = acceptedStudents[oneSUID]['Email'];
                            dataToAdd['avatarURL']      = acceptedStudents[oneSUID]['avatarURL'];
                            dataToAdd['customID']       = acceptedStudents[oneSUID]['customID'];

                            if(acceptedStudents[oneSUID]['parent1phone']){
                                dataToAdd['parent1phone']   = acceptedStudents[oneSUID]['parent1phone'];
                            }
                            if(acceptedStudents[oneSUID]['parent2phone']){
                                dataToAdd['parent2phone']   = acceptedStudents[oneSUID]['parent2phone'];
                            }
                            if(acceptedStudents[oneSUID]['RFIDCode']){
                                dataToAdd['RFIDCode']       = acceptedStudents[oneSUID]['RFIDCode'];
                            }


                            setWith(outputAsJSON, [oneSUID, 'AcceptedClasses', grade, subject, batch], acceptedStudents[oneSUID], Object);
                            set(outputAsJSON, [oneSUID] , {...acceptedStudents[oneSUID], ...outputAsJSON[oneSUID]});

                        }

                    }

                }
            }
        }

        for (let oneSUID_ in outputAsJSON){
            outputAsArr.push(outputAsJSON[oneSUID_]);
        }

        //sort this array alphabetically
        outputAsArr?.sort((a, b) => a.Name?.localeCompare(b.Name));

        return ({outputAsArr, outputAsJSON});
    }

    useEffect(() => {
        if (isDirectionRTL) {
            document.documentElement.classList.add('rtl');
            document.documentElement.setAttribute('data-direction', 'rtl')
        } else {
            document.documentElement.classList.remove('rtl');
            document.documentElement.setAttribute('data-direction', 'ltr')
        }

        if (themeColor) {
            styleSheetLink.href = `/css/${themeColor}.min.css`;
        }
    }, [themeColor, isDirectionRTL]);

    useEffect(() => {
        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme');
            styleSheetLink.href = "/css/dark_theme.css";
        } else if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme');
            styleSheetLink.href = "/css/style.css";
        }
    }, [themeType]);


    useEffect(() => {
        if (initURL === '') {
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("theme")) {
            dispatch(setThemeType(params.get('theme')));
        }
        if (params.has("nav-style")) {
            dispatch(onNavStyleChange(params.get('nav-style')));
        }
        if (params.has("layout-type")) {
            dispatch(onLayoutTypeChange(params.get('layout-type')));
        }
    }, [location.search, dispatch, initURL, location.pathname]);

    useEffect(() => {
        setLayoutType(layoutType);
        setNavStyle(navStyle);
    }, [layoutType, navStyle]);

    //based on the initial route of the URL the app will behave differently
    useEffect(() => {

        // If any of these matching strings are found in the URL, then we will skip loading full database since local db states are handled locally in these pages
        //sometimes we may need to check more specific strings in the URL in which case pathMatcherOverrideForNonDBLoad is checked against instead
        if(nonAuthForceRedirectRoutes.some(locationObj=> window.location.href.includes(locationObj.pathMatcherOverrideForNonDBLoad ? locationObj.pathMatcherOverrideForNonDBLoad : locationObj.path))){
            console.log('nonAuthForceRedirectRoutes detected so skipping full database load..');
            return false;
        }

        //this means someone tried to visit root domain
        if (location.pathname === '/'){
            console.log('Root Domain Visit Detected. Redirecting to welcome page..');
            history.push('/welcome');
        }

        //this means that an existing account already loaded with db tried to login with different account so need to re-initialize
        else if (authUser && db && initURL !== '' && initURL !== '/signup'){
            //console.log('INIT-APP-USEEFFECT LINE 657: ', {authUser, db, initURL});
            if (db['UID'] !== authUser){
                initialRead(authUser)
                    .then(()=>{
                        database.ref(`USERS/${authUser}/PublicInfo/UserProffession`).once('value')
                        .then(snapshot=>{
                            let thisProff = snapshot.val();
                            if (thisProff){
                                history.push(`/${returnMappedProfessionRoute_Route(thisProff)}/dashboard`);
                                console.log('APP INDEX JS Exiting Redirect to: ', `/${returnMappedProfessionRoute_Route(thisProff)}/dashboard`);
                            }
                        })
                    })
            }
        }

        //this means user entered from fresh account with sign-up
        else if (authUser && !db  && initURL === '/signup'){
            //console.log('INIT-APP-USEEFFECT LINE 675: ', {authUser, db, initURL});
            initialRead(authUser)
            .then(()=>{
                database.ref(`USERS/${authUser}/PublicInfo/UserProffession`).once('value')
                .then(snapshot=>{
                    let thisProff = snapshot.val();
                    if (thisProff){
                        history.push(`/${returnMappedProfessionRoute_Route(thisProff)}/dashboard`);
                        console.log('APP INDEX JS Exiting Redirect to: ', `/${returnMappedProfessionRoute_Route(thisProff)}/dashboard`);
                    }
                })
            })
        }

        //this means that completely fresh user login with no previous login attempts
        //or same account login again
        else if (authUser && !db && initURL !== '' && initURL !== '/signup') {
            //console.log('INIT-APP-USEEFFECT LINE 692: ', {authUser, db, initURL});
            initialRead(authUser)
                .then(() => {
                    if (db){
                        history.push(initURL);
                        console.log('APP INDEX JS REDIRECT TO: ', initURL);
                    }
                })
        }


        // fixed overflow issues
        let gxHomePage = document.getElementById('gx-donate-home-page');
        if (gxHomePage) {
            document.body.style.overflowY = 'scroll';
        }
        else {
            document.body.style.overflow = 'hidden';
        }

    }, [authUser, initURL, location, history, db, dispatch, initialRead]);

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ConfigProvider locale={currentAppLocale.antd} direction={isDirectionRTL ? 'rtl' : 'ltr'}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}
            >
                <Switch>
                    {withAuthForceRedirectRoutes.map((route, index) => (
                        <Route key={index} exact path={route.path} component={route.component} />
                    ))}
                    {nonAuthForceRedirectRoutes.map((route, index) => (
                        <Route key={index} exact path={route.path} component={route.component} />
                    ))}
                    <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
                </Switch>

            </IntlProvider>
        </ConfigProvider>
    )
};

export default memo(App);